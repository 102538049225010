import React, { useEffect, useRef } from 'react';
import { Box, IconButton, CssBaseline, Drawer,Dialog,Typography,DialogTitle,DialogContent } from '@mui/material';

import { connect } from "react-redux";
import { makeStyles, useTheme } from '@mui/styles';
import FilesList from './FilesList/FilesList';
import clsx from "clsx";
import {
  setFilesListAndEditorModalToggle,
  setFilesListAndEditorSelectedFileItem,
  setFilesListAndEditorSelectedFolderId
} from "../../store/actions/FilesListAndEditorActions";
import {
  setFoldersListMenu,
  setAllFoldersDetails
} from "../../store/actions/OtherActions";
import FilePreviewAndEditorContainer from "./FilePreviewAndEditorContainer/FilePreviewAndEditorContainer";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from '@mui/material/useMediaQuery';
import NavigationDrawer from "./NavigationDrawer";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import FoldersMenuBottomWithHeight from "./FoldersMenuBottomWithHeight";

import resolvesettings from '../resolvesettings';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: (state) => state.appBarHeight,
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  drawer: {
    width: "100%",
    flexShrink: 0,
    height: (state) => `calc(100% - ${state.appBarHeight}px)!important`,
    marginTop: (state) => state.appBarHeight,

  },
  drawerPaper: {
    width: "100%",
    height: (state) => `calc(100% - ${state.appBarHeight}px)!important`,
    marginTop: (state) => state.appBarHeight,
  },
  contentDrawerOpen: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginRight: (state) => state.drawerWidth,
    },

  },
  drawerCloseButton: {
    position: "absolute!important",
    backgroundColor: "white!important",
    zIndex: 10000,
    marginTop: "5px!important",
    marginLeft: "5px!important"
  },
  filePreviewAndEditorDialogTitle: {
    background: "#505050",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "10px!important",
    paddingBottom: "10px!important",
},
filePreviewAndEditorDialogTitleText: {
    color: "white",
},
filePreviewAndEditorDialogContent: {
    padding: "0!important",
    border:"0!important",
    overflow: "hidden!important"
},
filePreviewAndEditorDialogCloseButton:{
  color:"white!important",
  position: "absolute!important",
  right: 5,
  top: 6,
}
}));

const PagesContainer = (props) => {
  const theme = useTheme();
  const classes = useStyles({ appBarHeight: props.appBarHeight, drawerWidth: props.filesListAndEditorDrawerWidth });

  const FilePreviewAndEditorContainerRef = useRef(null);

  const getFoldersPathQuery = gql`
  query GetFoldersPath {
    get_folders_path {
      id
      path
      folder_name
    }
  }
`;

  let [
    getFoldersPath,
    {
      loading: getFoldersPathQueryLoading,
      error: getFoldersPathQueryError,
      data: getFoldersPathQueryResult,
    },
  ] = useLazyQuery(getFoldersPathQuery, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (getFoldersPathQueryResult && getFoldersPathQueryResult.get_folders_path) {

      let navMenus = [];
      const folderIds = new resolvesettings().getUserAllowFoldersId(props.authUserSettingsJson);
      props.setAllFoldersDetails(getFoldersPathQueryResult.get_folders_path);
      getFoldersPathQueryResult.get_folders_path.map((folderDetails, i) => {
        if (folderIds.includes(folderDetails.id)) {
          navMenus.push({
            text: folderDetails.folder_name,
            id: folderDetails.id,
            onClick: () => {
              props.setFilesListAndEditorSelectedFolderId(folderDetails.id);

            },
          });
        }
      });
      props.setFoldersListMenu(navMenus);
    }
  }, [getFoldersPathQueryResult]);
  useEffect(() => {

    getFoldersPath();
  }, []);

  const isSmOrXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdSmOrXs = useMediaQuery(theme.breakpoints.down('md'));

  const closeFilePreviewAndEditorModal = ()=>{
    props.setFilesListAndEditorModalToggle(false); // Close the drawer when clicking close button
    if(FilePreviewAndEditorContainerRef.current)
    FilePreviewAndEditorContainerRef.current.pauseVideoPlaying(true);
  }
  return (
    <Box className={classes.root}>
      <CssBaseline />

      {isMdSmOrXs && <NavigationDrawer menuList={props.foldersListMenu} />}
      {
       <Dialog
       style={{padding:10}}
       fullScreen
       aria-labelledby="customized-dialog-title"
       open={props.filesListAndEditorModalToggle}
       onClose={() => {
               
        closeFilePreviewAndEditorModal();
      }}

    
   >
       <DialogTitle
          
           id="customized-dialog-title"
           className={classes.filePreviewAndEditorDialogTitle}
       >
           <Typography variant="h6" className={classes.filePreviewAndEditorDialogTitleText}>
               Add/Edit Transcription
           </Typography>
           <IconButton
               aria-label="close"
               className={classes.filePreviewAndEditorDialogCloseButton}
               onClick={ () => {
                closeFilePreviewAndEditorModal();
               }}
           >
               <CloseIcon />
           </IconButton>
       </DialogTitle>
       <DialogContent dividers className={classes.filePreviewAndEditorDialogContent}>
       <FilePreviewAndEditorContainer ref={FilePreviewAndEditorContainerRef}/>
      </DialogContent>
      </Dialog>
       
      }
      <main className={clsx(classes.content)}>
        <div className={classes.contentContainer}>
          <FilesList />
        </div>
      </main>
      {!isMdSmOrXs &&
        <FoldersMenuBottomWithHeight />
      }
    </Box>
  );
}
const mapStateToProps = (state) => {
  return { ...state.AuthUserReducer, ...state.OtherReducer, ...state.FilesListAndEditorReducer };
};

export default connect(mapStateToProps, {
  setFilesListAndEditorModalToggle,
  setFilesListAndEditorSelectedFileItem,
  setFilesListAndEditorSelectedFolderId,
  setFoldersListMenu,
  setAllFoldersDetails
})(PagesContainer);